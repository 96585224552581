/* eslint-disable */
<template>
	<div id="strategylist">
		<!-- <div class="top">
			<img class="logo" src="https://static.ainvestcn.com/h5/topLogo%402x.fdcb6507.png" alt>
			<img v-if="unlogin" src="https://static.ainvestcn.com/h5/loginbtn2.png" alt class="button" @click="gotologin()">
		</div> -->
		<div class="middle">
			<div class="module">
				<p class="moduleTitle">策略优选</p>
				<div style="">
					<van-list v-model="loading" :finished="finished1" :error.sync="error" error-text="请求失败，点击重新加载" @load="onLoad">
						<div v-for="(item,index) in goodList" :key="index" class="card-item good-card-item" @click="showDetail(item.strategyId,item.classId)">
							<img :src="flagicon" class="backImg">
							<div class="topName">
								{{ item.strategyName }}
							</div>
							<div class="bottomData">
								<div class="left">
									<p class="valuer">{{ item.annualizedReturn }}</p>
									<p class="key">年化回报率</p>
								</div>
                <div class="right"></div>
								<div class="right">
									<p class="valuer">{{ item.maximumDrawdown }}</p>
									<p class="key">最大回撤
									</p>
								</div>
<!--								<div class="right">-->
<!--									<p class="valuer">{{ item.dayReturn120 }}</p>-->
<!--									<p class="key">近六个月-->
<!--									</p>-->
<!--								</div>div-->
								<div class="right">
									<p class="valuer">{{ item.dayReturn20 }}</p>
									<p class="key">近1个月</p>
								</div>
							</div>
						</div>
					</van-list>
				</div>
			</div>
			
			<div class="module">
				<p class="moduleTitle">全部策略</p>
				<!-- <van-dropdown-menu style="width:100%;z-index:1; box-shadow: 0px 2px 2px #888888">
					<van-dropdown-item v-model="filterTotal" :options="filter2option" @change="filter2change()" />
					<van-dropdown-item v-model="filter20" :options="filter2option" @change="filter2change()" />
					<van-dropdown-item v-model="filter120" :options="filter2option" @change="filter2change()" />
					<van-dropdown-item v-model="filterDraw" :options="filter2option" @change="filter2change()" />
				</van-dropdown-menu> -->
				<div class="titleMenu" style="width:100%;z-index:1;">
					<div class="titleMenuItem" :class="{asc: filterV === 2, desc: filterV === 1, active: filterV === 1 || filterV === 2}" @click="checkMenu(1, 2, 'annualized_return')">
						<span>年化回报率</span>
					</div>
          <div class="titleMenuItem">
<!--          <div class="titleMenuItem" :class="{asc: filterV === 6, desc: filterV === 5, active: filterV === 5 || filterV === 6}" @click="checkMenu(5, 6, '120day_return')">-->
<!--						<span>近六个月-->
<!--              &lt;!&ndash; <van-icon name="question-o" @click.stop="showTip(2)"/> &ndash;&gt;-->
<!--						</span>-->
          </div>
					<div class="titleMenuItem" :class="{asc: filterV === 6, desc: filterV === 3, active: filterV === 3 || filterV === 4}" @click="checkMenu(3, 4, 'maximum_drawdown')">
						<span>最大回撤
							<van-icon name="question-o" @click.stop="showTip(1)"/>
						</span>
					</div>

					<div class="titleMenuItem" :class="{asc: filterV === 8, desc: filterV === 7, active: filterV === 7 || filterV === 8}" @click="checkMenu(7, 8, '20day_return')">
						<span>近一个月</span>
					</div>
				</div>
				<div style="">

					<van-list v-model="loading" :finished="finished" finished-text="没有更多了" :error.sync="error" error-text="请求失败，点击重新加载" @load="onLoad">
						<div v-for="(item,index) in list" :key="index" class="card-item good-card-item" @click="showDetail(item.strategyId,item.classId)">
							<div class="topName">
								{{ item.strategyName }}
							</div>
							<div class="bottomData">
								<div class="left">
									<p class="valuer">{{ item.annualizedReturn }}</p>
									<p class="key">年化回报率</p>
								</div>
                <div class="right"></div>
								<div class="right" style="width: 30%;margin-right: 35px;">
									<p class="valuer">{{ item.maximumDrawdown }}</p>
									<p class="key" >最大回撤</p>
								</div>
<!--								<div class="right">-->
<!--									<p class="valuer">{{ item.dayReturn120 }}</p>-->
<!--									<p class="key">近六个月</p>-->
<!--								</div>-->
								<div class="right">
									<p class="valuer">{{ item.dayReturn20 }}</p>
									<p class="key">近一个月</p>
								</div>
							</div>
							<!-- <div class="bottomline">
								<div class="see">查看详情>></div>
							</div> -->
						</div>
					</van-list>
				</div>
			</div>
		</div>
		<div class="bottom">
			<van-tabbar route fixed>
<!--				<van-tabbar-item to="/home">-->
<!--					<img slot="icon" slot-scope="props" :src="props.active ? icons.home.active : icons.home.normal">-->
<!--					<span>首页</span>-->
<!--				</van-tabbar-item>-->
				<van-tabbar-item to="/strategylist">
					<img slot="icon" slot-scope="props" :src="props.active ? icons.mall.active : icons.mall.normal">
					<span>策略商城</span>
				</van-tabbar-item>
<!--				<van-tabbar-item to="/user">-->
<!--					<img slot="icon" slot-scope="props" :src="props.active ? icons.user.active : icons.user.normal">-->
<!--					<span>个人中心</span>-->
<!--				</van-tabbar-item>-->
			</van-tabbar>
		</div>
	</div>
</template>
<script>
import {
	List,
	Tabbar,
	TabbarItem,
	Icon,
	Tab,
	Notify,
	Dialog,
	Tag,
	DropdownMenu,
	DropdownItem
} from 'vant'
import { fetchStrategylist, islogin, logout, fetchGoodStrategylist, fetchAllStrategylist } from '@/api/theApi'
import flagicon from '@/icons/png/flagIcon-1.png'
export default {
	components: {
		[List.name]: List,
		[Tabbar.name]: Tabbar,
		[TabbarItem.name]: TabbarItem,
		[Icon.name]: Icon,
		[Tab.name]: Tab,
		[Tag.name]: Tag,
		[DropdownMenu.name]: DropdownMenu,
		[DropdownItem.name]: DropdownItem
	},
	data() {
		return {
			flagicon: flagicon,
			list: [],
			goodList: [],
			loading: true,
			error: false,
			finished: false,
			finished1: false,
			filter1value: 0,
			filter1index: 0,
			filter2value: 0,
			listall: [],

			filter1option: [{
					text: '适用资金(默认)',
					value: 0,
					index: 0,
					min: 0,
					max: 1000
				},
				{
					text: '2万-10万',
					value: 1,
					index: 1,
					min: 2,
					max: 10
				},
				{
					text: '10万-30万',
					value: 2,
					index: 2,
					min: 10,
					max: 30
				},
				{
					text: '30万-60万',
					value: 3,
					index: 3,
					min: 30,
					max: 60
				},
				{
					text: '60万-100万',
					value: 4,
					index: 4,
					min: 60,
					max: 100
				}
				// {
				//   text: '150万以上',
				//   value: 150
				// }
			],
			// filter2option: [{
			// 		text: '累计收益率',
			// 		value: 0
			// 	},
			// 	{
			// 		text: '年化回报率',
			// 		value: 1
			// 	},
			// 	{
			// 		text: '夏普比率',
			// 		value: 2
			// 	},
			// 	{
			// 		text: '日收益率',
			// 		value: 3
			// 	}
			// ],
			filterV: 1,
			filter2option: [{
					text: '升序',
					value: 'asc'
				},
				{
					text: '降序',
					value: 'desc'
				}
			],
			filterTotal: 'asc',
			filter20: 'asc',
			filter120: 'asc',
			filterDraw: 'asc',
			icons: {
				home: {
					name: '首页',
					active: 'https://static.ainvestcn.com/h5/icon/home-icon-a.png',
					normal: 'https://static.ainvestcn.com/h5/icon/home-icon.png'
				},
				mall: {
					name: '策略商城',
					active: 'https://static.ainvestcn.com/h5/icon/shop-icon-a.png',
					normal: 'https://static.ainvestcn.com/h5/icon/shop-icon.png'
				},
				user: {
					name: '用户中心',
					active: 'https://static.ainvestcn.com/h5/icon/user-icon-a.png',
					normal: 'https://static.ainvestcn.com/h5/icon/user-icon.png'
				}
			},
			unlogin: false
		}
	},
	// computed: {
	// 	recommend() {

	// 	}
	// },
	created() {
		this.init()
	},
	methods: {
		async init() {
			const res = await fetchAllStrategylist({
				pageNum: 1,
				pageSize: 10000,
				direction: 'desc',
				orderBy: 'annualized_return'
			})
			const resData = res.data
			const goodRes = await fetchGoodStrategylist()
			const goodResData = goodRes.data
			this.goodList = goodResData
			let rsltData = []
			resData.forEach(element => {
				rsltData = rsltData.concat(element)
			})
			this.list = rsltData.sort((a, b) => {
				return b - a
			})
			this.listall = rsltData
			this.filter2value = 0;
			// this.filter2change();
			this.loading = false
			this.finished = true
			this.finished1 = true
			const isloginRes = await islogin()
			const username = localStorage.getItem('username')

			if (isloginRes.login_status && username) {
				// Toast.loading('已经登陆')
				// const b64u = Base64.encode(username)
				this.unlogin = false
			} else {
				// localStorage.clear()
				this.unlogin = true
			}

			if (!isloginRes.login_status && username) {
				this.unlogin = true

				localStorage.clear()
			}

			if (isloginRes.login_status && !username) {
				this.unlogin = true

				// Toast.fail('登录过期\n请重新登陆')
				logout().then(() => {})
			}
		},
		onLoad() {
			// 异步更新数据
			setTimeout(() => {
				// 获取数据push数据
				// 加载状态结束
				this.loading = false
				// 数据全部加载完成
				if (this.list.length >= 1) {
					this.finished = true
					this.finished1 = true
				}
			}, 500)
		},
		showDetail(startegy_id, class_id) {
			// console.log(startegy_id)
			const targeturl = '/strategy/detail/' + startegy_id + '/' + class_id
			this.$router.push({
				path: targeturl
			})
		},
		gotologin() {
			this.$router.push({
				path: '/login'
			})
		},
		async checkMenu (a, b, c) {
			if (this.filterV === a) {
				this.filterV = b;
			} else {
				this.filterV = a;
			}
			this.finished = false;
			const res = await fetchAllStrategylist({
				pageNum: 1,
				pageSize: 10000,
				direction: this.filterV % 2 !== 0 ? 'desc' : 'asc',
				orderBy: c
			})
			const resData = res.data
			let rsltData = []
			resData.forEach(element => {
				rsltData = rsltData.concat(element)
			})
			this.list = rsltData.sort((a, b) => {
				return b - a
			})
			this.listall = rsltData
			this.finished = true;
		},
		showTip (type) {
			let msg = '';
			if (type === 1) {
				msg = '最大回撤率是衡量策略风险的重要指标。表示任意时点使用此策略往后推，资金总额走到最低点时的收益率回撤幅度的最大值，可理解为熊市最多亏多少。';
			} else {
				msg = '夏普比率是衡量在每承担1个单位风险的情况下，所获得的回报率是多少。夏普比率越高，说明投资的性价比越高、收益越高风险越小。';
			}
			// Notify({ type: 'warning', message: msg});
			Dialog.alert({
			  title: '提示',
			  message: msg,
			}).then(() => {
			  // on close
			});
		},
		filter1change() {
			// console.log(this.filter1value)

			// console.log(this.filter1option[this.filter1value].min)
			// console.log(this.filter1option[this.filter1value].max)
			// console.log(this.filter1option[this.filter1value])

			const min = this.filter1option[this.filter1value].min
			const max = this.filter1option[this.filter1value].max

			// 数据更替
			this.list = this.listall.filter(item => {
				const itemvalue = parseInt(item.applicable_funds.split('万')[0])
				return itemvalue >= min && itemvalue <= max
			})
		},
		filter2change() {
			if (this.filter2value === 0) {
				this.list = this.listall.sort((a, b) => {
					// console.log(a.total_return.replace('%', '') - b.total_return.replace('%', ''))
					return (
						parseFloat(b.total_return.replace('%', '')) -
						parseFloat(a.total_return.replace('%', ''))
					)
				})
			}
			if (this.filter2value === 1) {

				this.list = this.listall.sort((a, b) => {
					return (
						parseFloat(b.annualized_return.replace('%', '')) -
						parseFloat(a.annualized_return.replace('%', ''))
					)
				})
			}
			if (this.filter2value === 2) {
				this.list = this.listall.sort((a, b) => {
					return (
						parseFloat(b.sharp_value.replace('%', '')) -
						parseFloat(a.sharp_value.replace('%', ''))
					)
				})
			}
			if (this.filter2value === 3) {
				this.list = this.listall.sort((a, b) => {
					return (
						parseFloat(b.daily_return.replace('%', '')) -
						parseFloat(a.daily_return.replace('%', ''))
					)
				})
			}
			// 数据更替
		}
	}
}
</script>
<style lang="less" scoped>
#homev2 {
	position: relative;
	height: 100vh;
	width: 100vw;
	// display: flex;
	// flex-direction: column;
}

#strategylist {
	background: #ffffff;
	width: 100%;
	// height: 100%;
}

.top {
	// bottom: 4rem;
	top: 0;
	// height:3.5rem;
	position: fixed;
	height: 7.5vh;
	z-index: 1;
	width: 100vw;
	background: rgba(255, 255, 255, 1);
	box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.18);

	.logo {
		width: 42vw;
		// height: 5vh;
		margin-left: 3.4vw;
		margin-top: 1.2vh;
	}

	.button {
		width: 18vw;
		float: right;
		height: 9vw;
		margin-right: 3.5vw;
		margin-top: 1.7vh;
	}
}
.titleMenu{
	padding: 15px 0;
	padding-top: 0;
    background-color: white;
    font-size: 4vw;
    display: flex;
    justify-content: space-between;
}
.titleMenuItem{
	width: 23%;
	margin: 0 1%;
	text-align: center;
	position: relative;
}
.titleMenuItem span{

	position: relative;
}
.titleMenuItem span::before{
	position: absolute;
	content: '';
	top:2px;
	right: -13px;
	border-bottom: 5px solid rgb(220, 220, 220);
	border-right: 6px solid transparent;
	border-left: 6px solid transparent;
}
.titleMenuItem span::after{
	position: absolute;
	content: '';
	bottom:2px;
	right: -13px;
	border-top: 5px solid rgb(220, 220, 220);
	border-right: 6px solid transparent;
	border-left: 6px solid transparent;
}
.titleMenuItem.active{
	
}
.titleMenuItem.desc.active span::after{
	border-top-color: #1989fa;
}
.titleMenuItem.asc.active span::before{
	
	border-bottom-color: #1989fa;
}
.middle {
	bottom: 7.5vh;
	// top: 7.5vh;
	top: 0;
	// height: 15rem;
	position: absolute;
	width: 100vw;

	.login {
		width: 100vw;
		margin-top: 55.5vh;
	}

	.reg {
		margin-top: -1.7vh;
		width: 90.3vw;
		margin-left: 4.8vw;
	}

	.list-item {
		display: flex;

		div {
			position: absolute;
		}
	}

	.card-item {
		width: 94vw;
		padding: 1vh 3vw;
		height: 15vh;
		background-color: #ffffff;
		// box-shadow: 0vw 0vw 2vw 0vw rgba(204, 204, 204, 0.36);
		// border-radius: 1vw;
		background-color: rgba(255, 255, 255, 1);
		// box-shadow: 0px 0px 16px 0px rgba(204, 204, 204, 0.36);
		// border-radius: 6px;
		margin: 0 auto;
		// margin-top: 2vh;
		// background-image: url("https://static.ainvestcn.com/h5/listcard.png");
		border-top: 1px solid rgba(144, 144, 144, 0.9);
		background-size: 100% 100%;
		background-repeat: no-repeat;
		position: relative;

		.recommend-img {
			width: 7vh;
			height: 7vh;
			float: right;
		}

		.title {
			position: absolute;
			top: 3vh;
			left: 4vw;
			font-size: 5vw;
			height: 4vw;
			font-weight: bold;
		}

		.accumulate {
			position: absolute;
			top: 4vh;
			right: 30vw;
			font-size: 3vw;
			font-weight: 700;
			font-stretch: normal;
			letter-spacing: 0vw;
			color: #000000;
		}

		.valuea {
			position: absolute;
			top: 2.3vh;
			right: 4.2vw;

			font-size: 5.5vw;
			font-family: sans-serif;
			font-weight: bold;
			line-height: 2rem;
			color: rgba(255, 64, 64, 1)
		}

		.middleline {
			position: absolute;
			top: 7vh;
			left: 3vw;
			padding: 1vh;
			background-color: #f5f5f5;
			border-radius: 1vw;
			display: inline-flex;

			.marginright15 {
				margin-right: 2vw;
			}

			.key {
				color: rgba(102, 102, 102, 1);
				font-size: 3vw;
			}

			// hello teset
			.valuer {
				margin-left: 8px;
				color: rgba(255, 52, 52, 1);
				font-size: 3vw;

			}

			.valueg {
				margin-left: 8px;
				font-size: 3vw;
				color: rgba(61, 171, 31, 1)
			}
		}

		.bottomline {
			// position: absolute;
			// top: 12.5vh;
			// left:10vw;
			// height: 1.8vh;
			width: 90vw;
			// display: inline-flex;

			.formoney {
				// color: rgba(255, 64, 64, 1);
				// font-weight: bold;
				// line-height: 4.5vw;
				// font-size: 3vw;
				margin-top: 1vh;
				margin-left: 7vw;
				padding: 1vh 2vw;
				background-color: #fff0f0;
				border-radius: 1vh;
				font-size: 3vw;
				color: #ff4040;
			}

			.see {
				height: 3vw;
				font-size: 3vw;
				letter-spacing: 0vw;
				color: #666666;
				// margin: auto 0 auto 5vw;
			}

			.fund {
				position: absolute;
				top: 1vh;
				left: 2vw;
				display: block;
				width: 7vw;
				height: 7vw;
			}
		}
	}

	.good-card-item{
		height: 13vh;
		width: 94vw;
		padding: 3vh 3vw;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		.backImg{
			position: absolute;
			width: 70px;
			top: 0;
			right: 0;
		}
	}
}

.topName{
	font-size: 16px;
	font-weight: bold;
}
.bottomData{
	margin-top: 1vh;
	display: flex;
	.left{
		width: 37%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		.key{
			font-size: 0.9rem;
			margin-top: 2vh;
			color: rgb(140, 140, 140);
		}
		.valuer{
			font-size: 1.25rem;
			color: #ff4040;
		}
	}
	.right{
		width: 19%;
		margin: 0 1%;
		height: 100%;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		.key{
			font-size: 0.618rem;
			margin-top: 2vh;
			color: rgb(140, 140, 140);
		}
		.valuer{
			font-size: 1rem;
			color: rgb(60, 60, 60);
		}
	}
}
.bottom {
	bottom: 0;
	// height:3.5rem;
	height: 7.5vh;
	position: absolute;
	width: 100vw;

	// background-color: rgba(255, 255, 255, 0.048);
	.active {
		color: rgba(0, 0, 0, 1);
	}

	.normal {
		color: rgba(102, 102, 102, 1);
	}
}
.module:first-child{
	border-bottom: 15px solid #f5f5f5;
}
.moduleTitle{
	text-align: center;
	padding: 15px 4vw;
	background-color: white;
	font-weight: bold;
    font-size: 5.5vw;
}

</style>